import { WillInterface } from "@ifgengineering/hip-app-domain/src";
import { SectionStatus } from "./sectionStatus";

const familyStatus = (will: WillInterface): SectionStatus => {
  const { family } = will;

  if (!family) {
    return SectionStatus.NOT_YET_STARTED;
  }

  const { familyMembers, memberDisabled, memberReceivingTestedBenefits } =
    family;

  if (
    !familyMembers.length &&
    memberDisabled == null &&
    memberReceivingTestedBenefits == null
  ) {
    return SectionStatus.NOT_YET_STARTED;
  }

  if (
    Boolean(familyMembers.length) &&
    (memberDisabled == null || memberReceivingTestedBenefits == null)
  ) {
    return SectionStatus.IN_PROGRESS;
  }

  return SectionStatus.COMPLETE;
};

export default familyStatus;
