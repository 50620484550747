import {
  PortfolioDataInterface,
  WillInterface,
} from "@ifgengineering/hip-app-domain/src";
import sectionStatus, { SectionStatus } from "./sectionStatus";

export enum WillsStatus {
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  COMPLETE_NOT_PAID = "Complete - Not Paid",
  COMPLETE_PAID = "Complete - Paid",
  COMPLETE_WILL_GENERATED = "Complete - Will Generated",
}

const finished = (status: SectionStatus) => status === SectionStatus.COMPLETE;

const willsStatus = (
  will: WillInterface,
  portfolioData: PortfolioDataInterface
): WillsStatus => {
  const { about, family, assets, executors } = sectionStatus(
    will,
    portfolioData
  );

  const notYetStarted = about === SectionStatus.NOT_YET_STARTED;
  const completedAllRequiredSections =
    finished(about) &&
    finished(family) &&
    finished(assets) &&
    finished(executors);

  if (notYetStarted) {
    return WillsStatus.NOT_STARTED;
  }

  if (completedAllRequiredSections) {
    return WillsStatus.COMPLETE_NOT_PAID;
  }

  return WillsStatus.IN_PROGRESS;
};

export default willsStatus;
