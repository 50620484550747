import Fraction from "fraction.js";
import {
  FamilyMemberInterface as FamilyMember,
  FamilyMemberInterface,
} from "@ifgengineering/hip-app-domain/src";
import { FamilyMemberTypes } from "../../../components/Wills/constants/types";

export type FamilyValues = {
  husband?: FamilyMemberInterface;
  mother?: FamilyMemberInterface;
  father?: FamilyMemberInterface;
  paternalGrandfather?: FamilyMemberInterface;
  paternalGrandmother?: FamilyMemberInterface;
  maternalGrandmother?: FamilyMemberInterface;
  wives: FamilyMemberInterface[];
  sons: FamilyMemberInterface[];
  daughters: FamilyMemberInterface[];
  grandsons: FamilyMemberInterface[];
  granddaughters: FamilyMemberInterface[];
  brothers: FamilyMemberInterface[];
  sisters: FamilyMemberInterface[];
  paternalBrothers: FamilyMemberInterface[];
  paternalSisters: FamilyMemberInterface[];
  maternalBrothers: FamilyMemberInterface[];
  maternalSisters: FamilyMemberInterface[];
  fullBrothersSons: FamilyMemberInterface[];
  paternalBrothersSons: FamilyMemberInterface[];
  fullPaternalUncles: FamilyMemberInterface[];
  fathersPaternalBrothers: FamilyMemberInterface[];
  fullPaternalUnclesSons: FamilyMemberInterface[];
  fathersPaternalBrothersSons: FamilyMemberInterface[];
};

export type InheritanceShares = Record<number, Fraction>;

const filterByFamilyMemberType =
  (familyMembers: FamilyMemberInterface[]) => (FamilyMemberType: string) => {
    return familyMembers.filter(
      (familyMemeber) => familyMemeber.familyMemberType === FamilyMemberType
    );
  };

const getByFamilyMemberType =
  (familyMembers: FamilyMemberInterface[]) => (FamilyMemberType: string) => {
    return familyMembers.find(
      (familyMemeber) => familyMemeber.familyMemberType === FamilyMemberType
    );
  };

export const generateFamily = (
  familyMembers: FamilyMemberInterface[]
): FamilyValues => {
  const filterBy = filterByFamilyMemberType(familyMembers);
  const getBy = getByFamilyMemberType(familyMembers);

  const family: FamilyValues = {
    husband: getBy(FamilyMemberTypes.Husband),
    father: getBy(FamilyMemberTypes.Father),
    mother: getBy(FamilyMemberTypes.Mother),
    maternalGrandmother: getBy(FamilyMemberTypes.MaternalGrandmother),
    paternalGrandfather: getBy(FamilyMemberTypes.PaternalGrandfather),
    paternalGrandmother: getBy(FamilyMemberTypes.PaternalGrandmother),
    brothers: filterBy(FamilyMemberTypes.Brother),
    sisters: filterBy(FamilyMemberTypes.Sister),
    daughters: filterBy(FamilyMemberTypes.Daughter),
    sons: filterBy(FamilyMemberTypes.Son),
    granddaughters: filterBy(FamilyMemberTypes.Granddaughter),
    grandsons: filterBy(FamilyMemberTypes.Grandson),
    wives: filterBy(FamilyMemberTypes.Wife),
    paternalBrothers: filterBy(FamilyMemberTypes.PaternalBrothers),
    paternalSisters: filterBy(FamilyMemberTypes.PaternalSisters),
    maternalBrothers: filterBy(FamilyMemberTypes.MaternalBrothers),
    maternalSisters: filterBy(FamilyMemberTypes.MaternalSisters),
    fullBrothersSons: filterBy(FamilyMemberTypes.FullBrothersSon),
    paternalBrothersSons: filterBy(FamilyMemberTypes.PaternalBrothersSon),
    fullPaternalUncles: filterBy(FamilyMemberTypes.FullPaternalUncle),
    fathersPaternalBrothers: filterBy(
      FamilyMemberTypes.FathersPaternalBrothers
    ),
    fullPaternalUnclesSons: filterBy(FamilyMemberTypes.FullPaternalUnclesSons),
    fathersPaternalBrothersSons: filterBy(
      FamilyMemberTypes.FathersPaternalBrothersSons
    ),
  };

  return family;
};

const hasOffspring = (family: FamilyValues): boolean => {
  return (
    family.sons.length > 0 ||
    family.daughters.length > 0 ||
    family.granddaughters.length > 0 ||
    family.grandsons.length > 0
  );
};

const hasMultipleSiblings = (family: FamilyValues): boolean => {
  return (
    family.sisters.length +
      family.brothers.length +
      family.maternalSisters.length +
      family.maternalBrothers.length +
      family.paternalBrothers.length +
      family.paternalSisters.length >
    1
  );
};

const hasMaleHeir = (family: FamilyValues): boolean => {
  return family.sons.length > 0 || family.grandsons.length > 0;
};

const hasFemaleHeir = (family: FamilyValues): boolean => {
  return family.daughters.length > 0 || family.granddaughters.length > 0;
};

const distributeShares = (
  familyMembers: FamilyMember[],
  shares: InheritanceShares,
  totalShareAmount: Fraction
) => {
  // Avoid divide by 0 edge case
  if (familyMembers.length === 0) return;

  familyMembers.map(
    (member) =>
      (shares[member.id as number] = totalShareAmount.div(familyMembers.length))
  );
};

const assignShare = (
  familyMembers: FamilyMember[],
  shares: InheritanceShares,
  totalShareAmount: Fraction
) => {
  // Avoid divide by 0 edge case
  if (familyMembers.length === 0) return;

  familyMembers.map((member) => {
    if (!shares[member.id as number]) {
      shares[member.id as number] = new Fraction(0);
    }

    shares[member.id as number] =
      shares[member.id as number].add(totalShareAmount);

    return member;
  });
};

export const calculateTotalSharesIssued = (
  shares: InheritanceShares
): Fraction => {
  let total = new Fraction(0);
  for (const key in shares) {
    total = total.add(shares[key]);
  }

  return total;
};

export const calculateTotalShareAmountRemaining = (
  shares: InheritanceShares
): Fraction => {
  const total = calculateTotalSharesIssued(shares);
  return new Fraction(1).sub(total);
};

const calculateMaleAndFemaleShares = (
  males: FamilyMember[],
  females: FamilyMember[],
  shares: InheritanceShares
): { maleShare: Fraction; femaleShare: Fraction } => {
  const totalOutstandingShares =
    females.length > 0 ? females.length + males.length * 2 : males.length;

  if (totalOutstandingShares.valueOf() === 0)
    return { maleShare: new Fraction(0), femaleShare: new Fraction(0) };

  const remaining = calculateTotalShareAmountRemaining(shares);

  const eachShare = remaining.div(totalOutstandingShares);
  const maleShare = females.length > 0 ? eachShare.mul(2) : eachShare;
  const femaleShare = eachShare;

  return {
    maleShare: males.length > 0 ? maleShare : new Fraction(0),
    femaleShare: females.length > 0 ? femaleShare : new Fraction(0),
  };
};

const normaliseShares = (family: FamilyValues, shares: InheritanceShares) => {
  const total = calculateTotalSharesIssued(shares);

  if (total.compare(1) < 0) {
    // Exclude wife or husband
    let excludedTotal = total;
    const wivesIDs = family.wives.map((wife) => wife.id);

    if (family.wives.length > 0) {
      wivesIDs.forEach((wife) => {
        excludedTotal = excludedTotal.sub(shares[wife as number]);
      });
    }

    if (family.husband) {
      excludedTotal = excludedTotal.sub(shares[family.husband.id as number]);
    }

    const remaining = new Fraction(1).sub(total);

    for (const key in shares) {
      const wife = family.wives.some(
        (wife) => wife.id === Number.parseInt(key)
      );
      const husband = family.husband?.id === Number.parseInt(key);

      if (!wife && !husband) {
        const portion = shares[key].div(excludedTotal);
        shares[key] = shares[key].add(remaining.mul(portion));
      }
    }
  } else {
    for (const key in shares) {
      shares[key] = shares[key].div(total);
    }
  }
};

const calculateHusbandFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  if (!family.husband) return;
  if (family.wives.length > 0) return;

  shares[family.husband.id as number] = hasOffspring(family)
    ? new Fraction(1).div(4)
    : new Fraction(1).div(2);
};

const calculateWifeFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  if (family.husband) return;

  const totalShareAmount = hasOffspring(family)
    ? new Fraction(1).div(8)
    : new Fraction(1).div(4);

  distributeShares(family.wives, shares, totalShareAmount);
};

const calculatePaternalGrandmotherFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  if (!family.paternalGrandmother) return;

  // Set to 0 initially
  shares[family.paternalGrandmother.id as number] = new Fraction(0);

  // Blocking rules
  if (family.father) return;
  if (family.mother) return;

  if (family.maternalGrandmother) {
    shares[family.paternalGrandmother.id as number] = new Fraction(1).div(12);
  } else {
    shares[family.paternalGrandmother.id as number] = new Fraction(1).div(6);
  }
};

const calculateMaternalGrandmotherFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  if (!family.maternalGrandmother) return;

  // Set to 0 initially
  shares[family.maternalGrandmother.id as number] = new Fraction(0);

  // Blocking rules
  if (family.mother) return;

  if (family.paternalGrandmother && !family.father) {
    shares[family.maternalGrandmother.id as number] = new Fraction(1).div(12);
  } else {
    shares[family.maternalGrandmother.id as number] = new Fraction(1).div(6);
  }
};

const calculateMotherFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  if (!family.mother) return;

  shares[family.mother.id as number] = new Fraction(0);

  if (
    !hasOffspring(family) &&
    (family.wives.length > 0 || family.husband) &&
    family.father &&
    !hasMultipleSiblings(family)
  ) {
    const remaining = calculateTotalShareAmountRemaining(shares);
    shares[family.mother.id as number] = remaining.div(3);
    return;
  }

  if (!hasOffspring(family) && !hasMultipleSiblings(family)) {
    shares[family.mother.id as number] = new Fraction(1).div(3);
    return;
  }

  if (hasOffspring(family) || hasMultipleSiblings(family)) {
    shares[family.mother.id as number] = new Fraction(1).div(6);
    return;
  }
};

const calculateFatherFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  if (!family.father) return;

  shares[family.father.id as number] = new Fraction(0);

  if (hasMaleHeir(family) || hasFemaleHeir(family)) {
    shares[family.father.id as number] = new Fraction(1).div(6);
  }
};

const calculatePaternalGrandfatherFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  if (!family.paternalGrandfather) return;

  // Set to 0 initially
  shares[family.paternalGrandfather.id as number] = new Fraction(0);

  // Blocking rules
  if (family.father) return;

  if (hasMaleHeir(family) || hasFemaleHeir(family)) {
    shares[family.paternalGrandfather.id as number] = new Fraction(1).div(6);
  }
};

const calculateDaughtersFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  // Set to 0 initially
  assignShare(family.daughters, shares, new Fraction(0));

  // If there are sons, daughters get inheritance via taseeb
  if (family.sons.length !== 0) return;

  if (family.daughters.length === 1) {
    shares[family.daughters[0].id as number] = new Fraction(1).div(2);
    return;
  }

  const totalShareAmount = new Fraction(2).div(3);
  distributeShares(family.daughters, shares, totalShareAmount);
};

const calculateMaternalSiblingsFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  // Set to 0 initially
  assignShare(
    [...family.maternalBrothers, ...family.maternalSisters],
    shares,
    new Fraction(0)
  );

  // Blocking rules
  if (family.sons.length > 0) return;
  if (family.father) return;
  if (family.paternalGrandfather) return;
  if (family.daughters.length > 0) return;
  if (family.granddaughters.length > 0) return;
  if (family.grandsons.length > 0) return;

  // Special case when only one maternal sibling
  if (family.maternalBrothers.length + family.maternalSisters.length === 1) {
    assignShare(
      [...family.maternalBrothers, ...family.maternalSisters],
      shares,
      new Fraction(1).div(6)
    );
    return;
  }

  distributeShares(
    [...family.maternalBrothers, ...family.maternalSisters],
    shares,
    new Fraction(1).div(3)
  );
};

const calculateGranddaughtersFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  assignShare(family.granddaughters, shares, new Fraction(0));

  // Blocking rules
  if (family.sons.length > 0) return;
  if (family.daughters.length > 1) return;

  // Granddaughters get their inheritance via taseeb when grandsons exist
  if (family.grandsons.length > 0) return;

  const totalShareAmount =
    family.daughters.length === 1
      ? new Fraction(1).div(6)
      : family.granddaughters.length === 1
      ? new Fraction(1).div(2)
      : new Fraction(2).div(3);

  distributeShares(family.granddaughters, shares, totalShareAmount);
};

const calculateSistersFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  assignShare(family.sisters, shares, new Fraction(0));

  // Blocking rules
  if (family.father) return;
  if (family.paternalGrandfather) return;
  if (family.sons.length > 0) return;
  if (family.grandsons.length > 0) return;

  // Sisters get their inheritance via taseeb when brothers exist
  if (family.brothers.length > 0) return;
  // Sisters get their inheritance via taseeb when daughters or granddaughters exist
  if (family.daughters.length > 0 || family.grandsons.length > 0) return;

  const totalShareAmount =
    family.sisters.length === 1
      ? new Fraction(1).div(2)
      : new Fraction(2).div(3);

  distributeShares(family.sisters, shares, totalShareAmount);
};

const calculatePaternalSistersFixedShare = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  assignShare(family.paternalSisters, shares, new Fraction(0));

  // Blocking rules for paternal sister
  if (family.father) return;
  if (family.paternalGrandfather) return;
  if (family.sons.length > 0) return;
  if (family.grandsons.length > 0) return;
  if (family.brothers.length > 0) return;
  if (family.daughters.length !== 0) return;
  if (family.granddaughters.length !== 0) return;

  // Paternal Sisters get their inheritance via taseeb when Full Brother's Sons exist
  if (family.paternalBrothers.length > 0) return;

  let totalPaternalSisterShareAmount = new Fraction(0);
  if (family.sisters.length === 0) {
    totalPaternalSisterShareAmount =
      family.paternalSisters.length === 1
        ? new Fraction(1).div(2)
        : new Fraction(2).div(3);
  } else if (family.sisters.length === 1) {
    totalPaternalSisterShareAmount = new Fraction(1).div(6);
  }

  distributeShares(
    family.paternalSisters,
    shares,
    totalPaternalSisterShareAmount
  );
};

const calculateFatherTaseeb = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  if (!family.father) return [];

  // No ta'seeb if male descendants exist
  if (family.sons.length > 0) return [];
  if (family.grandsons.length > 0) return [];

  return [family.father];
};

const calculatePaternalGrandfatherTaseeb = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  if (!family.paternalGrandfather) return [];

  // Blocking rules
  if (family.father) return [];

  // No ta'seeb if male descendants exist
  if (family.sons.length > 0) return [];
  if (family.grandsons.length > 0) return [];

  return [family.paternalGrandfather];
};

const calculateSonsTaseeb = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  return family.sons;
};

const calculateDaughtersTaseeb = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  // No ta'seeb if there are no sons
  if (family.sons.length === 0) return [];

  return family.daughters;
};

const calculateGrandsonsTaseeb = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  assignShare(family.grandsons, shares, new Fraction(0));

  // Blocking rules
  if (family.sons.length > 0) return [];

  return family.grandsons;
};

const calculateGranddaughtersTaseeb = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  // Blocking rules
  if (family.sons.length > 0) return [];

  // No ta'seeb if there are no grandsons
  if (family.grandsons.length === 0) return [];

  return family.granddaughters;
};

const calculateBrothersAndSistersTaseeb = (
  family: FamilyValues,
  shares: InheritanceShares
): { maleM: FamilyMember[]; femaleM: FamilyMember[] } => {
  assignShare(family.brothers, shares, new Fraction(0));
  assignShare(family.paternalBrothers, shares, new Fraction(0));
  assignShare(family.fullBrothersSons, shares, new Fraction(0));
  assignShare(family.paternalBrothersSons, shares, new Fraction(0));
  assignShare(family.fullPaternalUncles, shares, new Fraction(0));
  assignShare(family.fathersPaternalBrothers, shares, new Fraction(0));
  assignShare(family.fullPaternalUnclesSons, shares, new Fraction(0));
  assignShare(family.fathersPaternalBrothersSons, shares, new Fraction(0));

  // Blocking rules
  if (family.father) return { maleM: [], femaleM: [] };
  if (family.paternalGrandfather) return { maleM: [], femaleM: [] };
  if (family.sons.length > 0) return { maleM: [], femaleM: [] };
  if (family.grandsons.length > 0) return { maleM: [], femaleM: [] };

  if (family.brothers.length > 0) {
    return { maleM: family.brothers, femaleM: family.sisters };
  }

  if (
    family.sisters.length > 0 &&
    (family.daughters.length > 0 || family.granddaughters.length > 0)
  ) {
    return { maleM: [], femaleM: family.sisters };
  }

  // paternal brothers (inherits with paternal sisters)
  if (family.paternalBrothers.length > 0) {
    return { maleM: family.paternalBrothers, femaleM: family.paternalSisters };
  }

  // paternal sister
  if (
    family.paternalSisters.length > 0 &&
    (family.daughters.length > 0 || family.granddaughters.length > 0) &&
    family.sisters.length === 0
  ) {
    return { maleM: [], femaleM: family.paternalSisters };
  }

  if (family.fullBrothersSons.length > 0) {
    return { maleM: family.fullBrothersSons, femaleM: [] };
  }

  if (family.paternalBrothersSons.length > 0) {
    return {
      maleM: family.paternalBrothersSons,
      femaleM: [],
    };
  }

  if (family.fullPaternalUncles.length > 0) {
    return {
      maleM: family.fullPaternalUncles,
      femaleM: [],
    };
  }

  if (family.fathersPaternalBrothers.length > 0) {
    return {
      maleM: family.fathersPaternalBrothers,
      femaleM: [],
    };
  }

  if (family.fullPaternalUnclesSons.length > 0) {
    return {
      maleM: family.fullPaternalUnclesSons,
      femaleM: [],
    };
  }

  if (family.fathersPaternalBrothersSons.length > 0) {
    return {
      maleM: family.fathersPaternalBrothersSons,
      femaleM: [],
    };
  }

  return { maleM: [], femaleM: [] };
};

const calculateFixedAmounts = (
  family: FamilyValues,
  shares: InheritanceShares
) => {
  calculateHusbandFixedShare(family, shares);
  calculateWifeFixedShare(family, shares);

  calculatePaternalGrandmotherFixedShare(family, shares);
  calculateMaternalGrandmotherFixedShare(family, shares);

  calculateMotherFixedShare(family, shares);
  calculateFatherFixedShare(family, shares);

  calculatePaternalGrandfatherFixedShare(family, shares);

  calculateDaughtersFixedShare(family, shares);
  calculateMaternalSiblingsFixedShare(family, shares);
  calculateGranddaughtersFixedShare(family, shares);

  calculateSistersFixedShare(family, shares);
  calculatePaternalSistersFixedShare(family, shares);
};

const calculateTaseeb = (family: FamilyValues, shares: InheritanceShares) => {
  const maleMembers: FamilyMember[] = [];
  const femaleMembers: FamilyMember[] = [];

  maleMembers.push(...calculateFatherTaseeb(family, shares));
  maleMembers.push(...calculatePaternalGrandfatherTaseeb(family, shares));

  maleMembers.push(...calculateSonsTaseeb(family, shares));
  femaleMembers.push(...calculateDaughtersTaseeb(family, shares));
  femaleMembers.push(...calculateGrandsonsTaseeb(family, shares));
  femaleMembers.push(...calculateGranddaughtersTaseeb(family, shares));

  const { maleM, femaleM } = calculateBrothersAndSistersTaseeb(family, shares);

  maleMembers.push(...maleM);
  femaleMembers.push(...femaleM);

  const totalShareRemaining = calculateTotalShareAmountRemaining(shares);
  // If more than whole estate is allocated, no ta'seeb
  if (totalShareRemaining.compare(0) < 0) return;

  const { maleShare, femaleShare } = calculateMaleAndFemaleShares(
    maleMembers,
    femaleMembers,
    shares
  );

  assignShare(maleMembers, shares, maleShare);
  assignShare(femaleMembers, shares, femaleShare);
};

export const calculateInheritance = (
  family: FamilyValues
): { shares: InheritanceShares; err?: Error } => {
  const inheritanceShares: InheritanceShares = {};
  calculateFixedAmounts(family, inheritanceShares);
  calculateTaseeb(family, inheritanceShares);
  // Normalise if more than 100% of estate allocated
  normaliseShares(family, inheritanceShares);

  const total = calculateTotalSharesIssued(inheritanceShares);

  return {
    shares: inheritanceShares,
    err:
      total.valueOf() < 1
        ? new Error("Less than 100% allocated to inheritors")
        : undefined,
  };
};
