import {
  PortfolioDataInterface,
  WillInterface,
} from "@ifgengineering/hip-app-domain/src";
import WillEnums from "../types/willEnums";
import aboutMeStatus from "./aboutMeStatus";
import assetsStatus from "./assetsStatus";
import executorsGuardiansStatus from "./executorsGuardiansStatus";
import familyStatus from "./familyStatus";
import giftsBequestsStatus from "./giftsBequestsStatus";
import messagesStatus from "./messagesStatus";
export enum SectionStatus {
  NOT_YET_STARTED = "Not yet started",
  IN_PROGRESS = "In progress",
  COMPLETE = "Complete",
}

export type StatusPerSection = Record<WillEnums, SectionStatus>;

const sectionStatus = (
  will: WillInterface,
  portfolioData: PortfolioDataInterface,
  sponsoredCharityId: string
): StatusPerSection => {
  const aboutMe = aboutMeStatus(will);

  const statusPerSection: StatusPerSection = {
    [WillEnums.getStarted]:
      aboutMe === SectionStatus.NOT_YET_STARTED
        ? SectionStatus.NOT_YET_STARTED
        : SectionStatus.COMPLETE,
    [WillEnums.aboutMe]: aboutMe,
    [WillEnums.myFamily]: familyStatus(will),
    [WillEnums.myAssets]: assetsStatus(portfolioData),
    [WillEnums.executorsGuardians]: executorsGuardiansStatus(will),
    [WillEnums.giftsBequests]: giftsBequestsStatus(will, sponsoredCharityId),
    [WillEnums.messages]: messagesStatus(will),
    [WillEnums.reviewSubmit]: SectionStatus.NOT_YET_STARTED,
  };

  return statusPerSection;
};

export default sectionStatus;
