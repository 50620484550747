import React, { useContext } from "react";
import { format } from "date-fns";
import { useMediaQuery } from "react-responsive";
import { keyTitle } from "../constants/keyTitle";
import { SectionBodyProps, SectionProps } from "../types";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import Accordion from "@willsComponents/Accordion";
import { CurrencyContext } from "@components/CurrencyContext";
import {
  HeadingContainer,
  Heading,
  Row,
  Question,
  Answer,
  NestedSpacer,
  NestedLabel,
  NestedWrapper,
  NestedField,
} from "../styled";
import { DebtLabels, DebtType } from "@components/PortfolioData/constants/debt";

const SectionBody = ({
  data,
  schema,
  item,
  key,
}: SectionBodyProps): JSX.Element => (
  <Row key={key}>
    <Question>{schema[item]}</Question>
    <Answer>
      {Array.isArray(data[item])
        ? nestedArray(data, item)
        : renderField(data, item)}
    </Answer>
  </Row>
);

export const Section: (props: SectionProps) => JSX.Element | null = ({
  section,
  schema,
  data,
}) => {
  if (!data) return null;
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const Content = () => (
    <div>
      <HeadingContainer>
        <Heading>{section}</Heading>
      </HeadingContainer>
      {Object.keys(schema).map((item, i) => {
        if (typeof schema[item] === "string") {
          return (
            <SectionBody schema={schema} item={item} data={data} key={i} />
          );
        }

        return Object.keys(schema[item]).map((nestedItem) => {
          if (nestedItem && data[item] && data[item][nestedItem]) {
            if (
              Array.isArray(data[item][nestedItem]) &&
              !data[item][nestedItem].length
            ) {
              return null;
            }

            return (
              <SectionBody
                schema={schema[item]}
                item={nestedItem}
                data={data[item]}
                key={i}
              />
            );
          }
        });
      })}
    </div>
  );
  if (isMobile)
    return (
      <Accordion title={section}>
        <Content />
      </Accordion>
    );
  return <Content />;
};

export const nestedArray: (data: any, item: any) => JSX.Element = (
  data,
  item
) =>
  data[item].map((obj: any, i: number) => (
    <NestedSpacer key={i}>
      {Object.keys(obj).map((nestKey: any, j) =>
        Array.isArray(obj[nestKey]) ? (
          <div key={j}>{nestedArray(obj, nestKey)}</div>
        ) : nestKey === "id" ? null : (
          <NestedWrapper key={j}>
            <NestedLabel>{getText(nestKey)}:</NestedLabel>
            <NestedField>{renderField(obj, nestKey)}</NestedField>
          </NestedWrapper>
        )
      )}
    </NestedSpacer>
  ));

const getText = (textKey: keyof typeof keyTitle) => keyTitle[textKey];
const capitalize = (str: string) => str.replace(str[0], str[0]?.toUpperCase());

export const renderField: (data: any, item: any) => JSX.Element = (
  obj,
  item
) => {
  // If product want something else instead of "-" for empty values,
  // change the return value in the next line
  if (obj[item] === undefined || obj[item] === null) return "-";
  const { formatCurrency } = useContext(CurrencyContext);
  switch (true) {
    case typeof obj[item] == "boolean":
      return obj[item] ? "Yes" : "No";
    case item === "address":
      return nestedObject(obj[item]);
    case item === "value":
    case item === "totalValue":
    case item === "cashISA":
    case item === "cashInHand":
    case item === "totalCashValue":
    case item === "cashInAccount":
    case item === "estimatedEstateValue":
    case item === "monetaryValue":
    case item === "donation":
    case item === "money":
      return formatCurrency(obj[item]);
    case item === "percentageEstate":
    case item === "sponsBqPercentage":
      return obj[item].toLocaleString("en-GB", {
        style: "percent",
        maximumFractionDigits: 4,
      });
    case item === "dob":
      return <div>{format(obj[item], "dd/MM/YYYY")}</div>;
    case item === "type" && Object.keys(DebtType).includes(obj[item]):
      return DebtLabels[obj[item] as DebtType];
    default:
      return typeof obj[item] === "string" ? capitalize(obj[item]) : obj[item];
  }
};

export const nestedObject: (data: {
  [p: string]: any;
}) => JSX.Element | null = (data) => {
  if (!data) return null;
  return (
    <div>
      {Object.keys(data).map((item, i) => (
        <Answer key={i}>{data[item]}</Answer>
      ))}
    </div>
  );
};
