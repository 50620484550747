import { WillInterface } from "@ifgengineering/hip-app-domain/src";
import { SectionStatus } from "./sectionStatus";

const giftsBequestsStatus = (
  will: WillInterface,
  sponsoredCharityId: string
): SectionStatus => {
  const { bequests } = will;

  if (
    !bequests?.bequestIndividuals?.length &&
    !bequests?.bequestCharity?.length
  ) {
    return SectionStatus.NOT_YET_STARTED;
  }
  const hasSponsoredGift = bequests?.bequestCharity?.find(
    (bq) => bq.partnerCharity?.id === sponsoredCharityId
  );

  if (sponsoredCharityId && !hasSponsoredGift) {
    return SectionStatus.IN_PROGRESS;
  }

  return SectionStatus.COMPLETE;
};

export default giftsBequestsStatus;
