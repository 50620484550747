import { MarriageStatusType } from "@ifgengineering/hip-app-domain/src";
import React from "react";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";

type Props = {
  wives: FamilyMemberObject[];
  marriageStatusType: MarriageStatusType;
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

export const Wives = ({
  wives,
  marriageStatusType,
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  return (
    <FamilyComposition
      title="Please enter your wife’s details"
      buttonText="Add a wife"
      modalTitle="About your wife"
      limit={4}
      data={wives}
      onBack={onBack}
      onSave={(value) => {
        onChange({
          ...value,
          familyMemberType: FamilyMemberTypes.Wife,
          marriageStatustype: marriageStatusType,
        });
      }}
      onRemove={(id) => onDelete(id)}
      onFinish={() => onFinish(Boolean(wives.length))}
    />
  );
};
